<template>
  <div style="height: 100vh; background-color: transparent">
    <div class="sticky">
      <div class="header" style="padding-bottom:30px">
        <van-row gutter="10">
          <van-col @click="onClickBack">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
              style="height: 25px"
            />
            <span style="font-size: 25px; color: white;font-weight:bold">出团信息</span>
          </van-col>
        </van-row>
      </div>
      <tourCard
        :tour="tour"
        v-for="(tour, idx) in onGoingTours"
        :key="'onGoingTour:' + idx"
      ></tourCard>
      <card>
        <template #content>
          <van-row>
            <van-col span="8" v-for="(item, idx) in tourTypeMenu" :key="'tourMenu:' + idx" 
              @click="onMenuClick(item.type, idx)">
              <van-row class="icon-row" justify="center">
                <svg-icon
                  :data_iconName="tourTypeSelect === idx? item.activeicon: item.icon"
                  :className="tourTypeSelect === idx? 'tab_icon-active':'tab_icon'"
                /> 
              </van-row>
              <van-row class="icon-title-row">
                <span style="font-size: 15px; font-weight: 100;">{{item.text}}</span>
              </van-row>
            </van-col>
          </van-row>
        </template>
      </card>
    </div>
    <div v-if="tourTypeSelect === 0 && unStartedTours.length === 0">
      <van-empty description="没有待出发的行程" v-if="unStartedTours.length === 0" />
    </div>
    <div v-if="tourTypeSelect === 1 && finishedTours.length === 0">
        <van-empty v-if="finishedTours.length === 0" description="没有已完成的行程" />
    </div>
    <div v-if="tourTypeSelect === 2 && canceledTours.length === 0">
      <van-empty v-if="canceledTours.length === 0" description="没有已取消的行程" />
    </div>
    <div v-if="tourTypeSelect === 0" class="tour-area" 
      :style="{'height': onGoingTours.length>0?'50vh':'80vh'}">
      <van-pull-refresh v-model="isLoading" @refresh="loadTours(0)" 
        :style="{'padding-bottom': unStartedTours.length < 3? '1rem': '0'}">
        <van-list v-model="listLoading" :finished="listFinished" 
          :finished-text="unStartedTours.length > 0? '没有更多了': ''" @load="loadList(0)">
          <tourCard
            v-for="(tour, idx) in unStartedTours"
            :key="'unStartedTours:' + idx"
            :tour="tour"
          ></tourCard>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-if="tourTypeSelect === 1" class="tour-area"
      :style="{'height': onGoingTours.length>0?'50vh':'80vh'}">
      <van-pull-refresh v-model="isLoading" @refresh="loadTours(2)" 
        :style="{'padding-bottom': finishedTours.length < 3? '1rem': '0'}">
        <van-list v-model="listLoading" :finished="listFinished" 
          :finished-text="finishedTours.length > 0? '没有更多了': ''" @load="loadList(2)">
          <tourCard
            v-for="(tour, idx) in finishedTours"
            :key="'finishedTours:' + idx"
            :tour="tour"
          ></tourCard>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-if="tourTypeSelect === 2" class="tour-area"
      :style="{'height': onGoingTours.length>0?'50vh':'80vh'}">
      <van-pull-refresh v-model="isLoading" @refresh="loadTours(3)" 
        :style="{'padding-bottom': canceledTours.length < 3? '1rem': '0'}">
        <van-list v-model="listLoading" :finished="listFinished" 
          :finished-text="canceledTours.length > 0? '没有更多了': ''" @load="loadList(3)">
          <tourCard
            v-for="(tour, idx) in canceledTours"
            :key="'finishedTours:' + idx"
            :tour="tour">
          </tourCard>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tourCard from "@/views/components/TourCard";
import card from "@/views/components/Card";
export default {
  name: "tourMain",
  components: {
    tourCard,
    card,
  },
  data() {
    return {
      isLoading: false,
      listLoading: false,
      statusSelect: 0,
      onGoingTourNodes: [],
      tourTypeSelect: 0,
      tourTypeMenu: [
        {
          text: "待出发",
          icon: "planed",
          type: 0,
          activeicon: "planed-active",
        },
        {
          text: "已完成",
          icon: "finished",
          type: 2,
          activeicon: "finished-active",
        },
        {
          text: "已取消",
          icon: "canceled",
          type: 3,
          activeicon: "canceled-active",
        },
      ],
      paging: {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
        next_page: null
      }
    };
  },
  computed: {
    ...mapGetters("travel_list", {
      onGoingTours: "onGoingTours",
      unStartedTours: "unStartedTours",
      finishedTours: "finishedTours",
      canceledTours: "canceledTours"
    }),
    listFinished() {
      return this.paging.next_page == null
    },
  },
  created() {
    const _self = this
    this.$store.dispatch("updatePageHead", "出团");
    this.$store.dispatch("travel_list/getAllTravelLists", 1)
    this.$store.dispatch("travel_list/getAllTravelLists", 0).then((res) => {
      if(res.status == 200) {
        _self.fillPaging(res)
      }
    })
    this.$store.dispatch("wode/getPersonInfo")
  },
  methods: {
    onClickBack() {
      this.$router.push({ path: "/home" });
    },
    onMenuClick(type, idx) {
      const _self = this
      this.tourTypeSelect = idx
      this.$store.dispatch("travel_list/getAllTravelLists", type).then((res) => {
        if(res.status == 200) {
          _self.fillPaging(res)
        }
      })
    },
    loadTours(type) {
      const _self = this
      this.$store.dispatch("travel_list/getAllTravelLists", type).then((lists) => {
        if(lists) {
          _self.isLoading = false
        }
      })
    },
    loadList(type) {
      const _self = this
      if(this.paging.next_page != null) {
        this.$store.dispatch("travel_list/getAllTravelLists", type, 
          this.paging.next_page).then((res) => {
            if(res.status === 200) {
              _self.listLoading = false
              _self.fillPaging(res)
              _self.$store.dispatch('travel_list/syncTravelList', res.data, type)
            }
          })
      }
    },
    fillPaging(res) {
      this.paging.total_count = res.total_count
      this.paging.total_pages = res.total_pages
      this.paging.current_page = res.current_page
      this.paging.next_page = res.next_page
    }
  },
};
</script>

<style scoped>
.icon {
  width:80px;
}
.icon-row {
  height:40px;
  text-align: center;
}
.icon-title-row {
  text-align: center;
  height: 40px;
}
.tab_icon-active{
 font-size: 1.3rem;
}
.tab_icon {
  font-size: 1rem;
}
.tour-area {
  padding: 0 .2rem 4rem .2rem;
  overflow-y: scroll;
}
.sticky {
    padding: 0 .2rem 0 .2rem;
    background-image: linear-gradient(
179deg
, #6a92f6 0%, #5376e5 16%, #4067ee 100%);;
}
</style>